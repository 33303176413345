import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

import genopets from '../../assets/images/genopets/genopets-banner.webp';
import genopetsOne from '../../assets/images/genopets/genopets-1.webp';
import genopetsTwoA from '../../assets/images/genopets/genopets-2-a.webp';
import genopetsTwoB from '../../assets/images/genopets/genopets-2-b.webp';
import genopetsTwoC from '../../assets/images/genopets/genopets-2-c.webp';
import genopetsTwoD from '../../assets/images/genopets/genopets-2-d.webp';
import genopetsTwoF from '../../assets/images/genopets/genopets-2-f.webp';
import genopetsTwoG from '../../assets/images/genopets/genopets-2-g.webp';
import genopetsThreeA from '../../assets/images/genopets/genopets-3-a.webp';
import genopetsThreeB from '../../assets/images/genopets/genopets-3-b.webp';
import genopetsThreeC from '../../assets/images/genopets/genopets-3-c.webp';
import genopetsFour from '../../assets/images/genopets/genopets-4.webp';
import genopetsFourA from '../../assets/images/genopets/genopets-4-a.webp';
import genopetsFourB from '../../assets/images/genopets/genopets-4-b.webp';
import genopetsFiveA from '../../assets/images/genopets/genopets-5-a.webp';
import genopetsFiveB from '../../assets/images/genopets/genopets-5-b.webp';
import genopetsFiveC from '../../assets/images/genopets/genopets-5-c.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Genopets extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">World’s First Move-to-Earn NFT Game</div>
                <div className="subtitle section-margin">
                  Converting real-life activity into rewards in a gamified metaverse
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#FF1866'}}> GENOPETS</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Develop a gamified virtual universe where players can convert their real-life
                      movement to advance their Genopet NFT.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Collaborated with the Genopets team to deliver the first two releases, the
                      Genopet NFT Drop and the Habitat NFT Drop, as well as create a set of
                      minigames.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      Genopets organically grew to over 80k active players in less than six months,
                      with a D30 retention averaging a staggering 57% (25x the industry average).
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://blockworks.co/news/genopets-raises-8-3m-for-a-virtual-game-with-real-benefits"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Genopets raises $8.3M for a virtual game with real benefits
                    </a>{' '}
                    - Blockworks
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.nfttech.com/newsroom/why-we-love-genopets"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Why we love Genopets
                    </a>{' '}
                    - NFT Tech
                  </p>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/YtVwo2A4Ebc"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>

                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://www.genopets.me/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Genopets
                      </a>{' '}
                      was the gamification of an active lifestyle. It was envisioned as the world’s
                      first Free-to-Play, Move-to-Earn NFT mobile game that made it fun and
                      rewarding to live an active lifestyle. A Genopet was a digital pet whose
                      evolution and growth were inextricably linked to a player’s own. Steps users
                      took everyday – walking around the mall, jogging at the gym, running between
                      office meetings – would convert into XP to power their journey through the
                      Genoverse as they explored, battled, and evolved, earning crypto all the
                      while.
                    </p>
                    <br />
                    <p className="description">
                      Genopets was more than counting steps and rewarding users for how much they
                      walked. The game was predicated on them building a bond with their digital
                      companions and taking care of them daily by taking care of themselves. The
                      connection between oneself and their pet is a powerful force, and it was this
                      emotional force which served as the premise of Genopets – motivating users to
                      live active lifestyles.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100" src={genopetsOne} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      The Genopets team was looking for development partners who could complement
                      their in-house team to create a well-designed gaming experience for their
                      growing community. Following a thorough vetting process, they engaged Tintash
                      in September 2021 based on our deep expertise in developing mobile games,
                      particularly on the Unity platform, as well as creating gamified social
                      experiences.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      The Genopets team had already conceptualized a very specific game design and
                      visuals. After several brainstorming sessions where we explored the game
                      concept in detail with the Genopets team, we devised a project development
                      plan. The plan was geared towards the scheduled NFT Drops.
                    </p>
                    <br />
                    <br />

                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Genopet NFT Drop
                    </p>
                    <p className="description mt-0">
                      We kickstarted the development process by implementing the Genopet Mining
                      feature. This entailed solving for the various intricate technicalities
                      revolving around Unity's communication with Web and Flutter.
                    </p>
                    <br />
                    <p className="description">
                      The feature included procedurally generated Pets that players could mint.
                      Unity’s system included hundreds of pet models and augments that were
                      generated upon receiving payload from the web to create a unique Genopet for
                      every player. Each pet was unique in terms of visuals, augments, and quirky
                      looks, especially the shaders. Our team created a Genopet Shader that allowed
                      for countless visualization possibilities for the pets.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-4 col-6 d-flex justify-content-center align-items-center">
                      <img className="sub-pic" src={genopetsTwoA} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-4 col-6 d-flex justify-content-center align-items-center">
                      <img className="sub-pic" src={genopetsTwoB} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-4 col-6 d-flex justify-content-center align-items-center">
                      <img className="sub-pic" src={genopetsTwoC} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-4 col-6 d-flex justify-content-center align-items-center">
                      <img className="sub-pic" src={genopetsTwoD} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-4 col-6 d-flex justify-content-center align-items-center">
                      <img className="sub-pic" src={genopetsTwoF} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-4 col-6 d-flex justify-content-center align-items-center">
                      <img className="sub-pic" src={genopetsTwoG} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-12 p-0 d-flex align-items-center justify-content-center">
                      <div className="image-description text-center mb-0">Genopets</div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      All mini-features combined, the team made it to the first major release
                      featuring the Genopet NFT Drop in November 2021. That release proved to be a
                      huge success, and was only made possible by the support of our experienced VFX
                      artists at Tintash.
                    </p>
                    <br />
                    <br />

                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      Habitat NFT Drop
                    </p>
                    <p className="description mt-0">
                      Genopets lived inside their Habitats. As with Genopets, Habitats were likewise
                      procedurally generated NFTs, making them unique to each player. Giving each
                      player a unique Habitat required high computation power and could not be
                      generated every time on client devices. In order to overcome this problem, our
                      team conducted extensive R&D to figure out a solution that could generate the
                      Habitats without disruption.
                    </p>
                    <br />
                    <p className="description">
                      It took us almost two months and multiple Unity iterations to enable the game
                      to generate the same habitat on any device smoothly, which was a major win for
                      the client. The Habitat NFT Drop was released in April 2022.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-2 p-0 d-flex align-items-center justify-content-center">
                      <div className="image-description text-center mb-0">Genopet Habitats</div>
                    </div>
                    <div className="col-lg-10 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={genopetsThreeA} alt="jobbox" />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center mb-5 mt-lg-2">
                    <div className="col-lg-6 p-0 pr-lg-1 pt-2">
                      <img className="w-100" src={genopetsThreeB} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-6 p-0 pl-lg-1 pt-2">
                      <img className="w-100" src={genopetsThreeC} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description mb-2" style={{fontWeight: 'bold'}}>
                      {' '}
                      Minigames
                    </p>
                    <p className="description mt-0">
                      For a well-rounded user experience, we created minigames to engage users.
                      There were two minigames – feed and fetch – that Genopets could play with
                      them. Playing these would positively impact a Genopet’s stats, improving the
                      pet’s development experience.
                    </p>
                    <br />
                    <p className="description">
                      In the feed minigame, the user would throw food to the pet by aiming and
                      releasing it at the right time. When the pet ate the food, its nutrition value
                      would increase. In the fetch minigame, the user aimed for a glowing circle by
                      pulling back the toy and releasing it. When the glowing circle was hit, the
                      pet would pick up the toy and its joy value would increase. Sometimes, the pet
                      would even throw the toy back to the user.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center align-items-center section-margin">
                    <div className="image-description text-center mb-0">Pet feed minigame</div>
                    <img className="col-6" src={genopetsFour} alt="bed bath and beyond" />
                    <div className="image-description text-center mb-0">Pet fetch minigame</div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      Despite a challenging timeline, Tintash helped Genopets complete the first
                      phase of their product roadmap with a private beta launch. The version allowed
                      players to acquire Genesis Genopets and Habitats, as well as summon Baby
                      Genopets and evolve them.
                    </p>
                    <br />
                    <p className="description">
                      Using data tracked by smartphones or fitness wearables, the game enabled
                      players to bank steps, set personal goals, convert Energy and level up with
                      XP. Users could manage, rent, or Terraform Habitats, as well as trade on the
                      NFT marketplace partners.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin col-12 mr-auto ml-auto">
                    <img className="col-4" src={genopetsFiveA} alt="bed bath and beyond" />
                    <img className="col-4" src={genopetsFiveB} alt="bed bath and beyond" />
                    <img className="col-4" src={genopetsFiveC} alt="bed bath and beyond" />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Genopets managed to garner early traction. Starting from a few thousand users
                      in August, 2022, the game organically grew to over 80k users in less than six
                      months, with an impressive MoM growth of 110%. With over a 180k strong Discord
                      community, 80% of that growth came from player referrals.
                    </p>
                    <br />
                    <p className="description">
                      The product has also proved sticky. Day30 player retention averaged a
                      staggering 57% which was 25 times higher than the top 25% Casual Game D30
                      industry average. This shows that players were coming into the game and
                      weren’t leaving. While the user base was growing, retention was fairly stable.
                    </p>
                    <br />
                    <p className="description">
                      Backed up by{' '}
                      <a
                        className="description"
                        href="https://blockworks.co/news/genopets-raises-8-3m-for-a-virtual-game-with-real-benefits"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        leading venture capitalists
                      </a>{' '}
                      , Genopets is currently engaged in the next phase of introducing the core
                      gameplay mechanics. The long term objective is to allow players to connect
                      in-game and explore the Genoverse together. Tintash continues to work closely
                      with the Genopets team on these future releases. Alongside contributing on the
                      Unity front, our team increasingly supports Genopets on their art needs.
                    </p>
                  </div>
                  <div className="section-margin" />
                </div>
                {/* <div className="col-12 p-0 my-5">
                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-lg-4 col-sm-9 my-3">
                      <img className="w-100 sub-pic" src={jobboxThreeA} alt="bed bath and beyond" />
                      <div className="image-description text-center my-3">
                        Renters could easily find the right equipment
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-9 my-3">
                      <img className="w-100 sub-pic" src={jobboxThreeB} alt="bed bath and beyond" />
                      <div className="image-description text-center my-3">
                        Suggested pricing built to help owners remain competitive
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-9 my-3">
                      <img className="w-100 sub-pic" src={jobboxThreeC} alt="bed bath and beyond" />
                      <div className="image-description text-center my-3">
                        Owners could link items to inventory to increase rentals
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Genopets;
